$baseLine: 16px;
$copyLinkBoxWidth: 280px;

.copyToClipboardTooltip {
  background-color: #fff;
  border: 8px solid #e3e3e3;
  cursor: auto;
  font-size: 13px;
  left: 50%;
  padding: 10px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: $copyLinkBoxWidth;
  z-index: 99998;

  :global(body:not(.mol-mobile)) & {
    border-color: #c0c0c0;
    border-width: 1px;
    position: absolute;
    top: calc(100% + #{0.5 * $baseLine + 1});
    transform: translateX(-50%);

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &::after {
      top: - $baseLine;
      border-color: transparent transparent #fff;
      border-width: 0.5 * $baseLine;
      margin-left: -0.5 * $baseLine;
    }

    &::before {
      top: - $baseLine - 2;
      border-color: transparent transparent #c0c0c0;
      border-width: 0.5 * $baseLine + 1;
      margin-left: -0.5 * $baseLine - 1;
    }
  }

  input {
    border: 1px solid #c0c0c0;
    box-sizing: border-box;
    font-size: 13px;
    height: 40px;
    padding: 0.25 * $baseLine;
    width: 100%;

    :global(body:not(.mol-mobile)) & {
      height: auto;
    }
  }

  &[data-type="url"] {
    input {
      background: url('./assets/link-outline-white-no-border.svg') no-repeat;
      background-size: $baseLine $baseLine;
      background-position: 3px;
      padding-left: 1.5 * $baseLine;
    }
  }

  .copyButton,
  .manualCopyPrompt {
    display: none;
  }

  &[data-is-copy-supported="true"] .copyButton {
    display: block;
  }

  &[data-is-copy-supported="false"] .manualCopyPrompt {
    display: block;
  }

  .successMessage {
    display: none;
    color: #0cac0d;
    font-family: var(--mol-default-font-family);
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &::before {
      background-color: #fff;
      background-image: url("./assets/icon-success.svg");
      background-size: cover;
      border-radius: 50%;
      content: "";
      display: inline-block;
      font-size: 14px;
      height: 1em;
      line-height: 1;
      margin-right: 0.25em;
      transform: translateY(-2px);
      vertical-align: middle;
      width: 1em;
    }
  }

  &[data-is-text-copied="true"] .successMessage {
    display: block;
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: relative;
  }

  .copyButton {
    background-color: #004db3;
    border-radius: 2px;
    border-width: 0;
    color: #fff;
    cursor: pointer;
    font-family: var(--mol-default-font-family);
    font-weight: bold;
    padding: 11px 23px 13px;

    &:hover {
      opacity: 0.85;
    }

    &:active {
      background: red;
    }
  }

  .manualCopyPrompt {
    color: #000;
    font-size: 14px;
    margin: 10px 0;
  }

  .closeButton {
    background-color: #fff;
    border-radius: 50%;
    border-width: 0;
    cursor: pointer;
    height: 28px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(calc(50% + 4px), calc(-50% - 4px));
    width: 28px;

    &:hover svg {
      opacity: 0.75;
    }

    > span {
      border: 0;
      clip: rect(0 0 0 0);
      -webkit-clip-path: inset(50%);
      clip-path: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      white-space: nowrap;
    }
  }
}
