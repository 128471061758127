:global {
  // sticky bar
  .mol-products-sticky-bar {
    display: none;

    &.sticky {
      z-index: 1300;
      position: fixed;
      bottom: 0;
      height: 60px;
      width: 100%;
      background-color: white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-shadow: -1px 1px 8px 0 rgba(0, 0, 0, 0.5);

      .mol-product-sticky-cta-wrapper {
        width: 100%;
        margin: auto;

        .mol-product-cta {
          appearance: none;
          border: none;
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          border-radius: 2px;
          text-decoration: none;
          font-weight: bold;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  
  // sticky bar on desktop
  .mol-desktop {
    .mol-products-sticky-bar.sticky {
      .mol-product-sticky-cta-wrapper {
        margin: auto 10px;

        .mol-product-cta:hover {
          opacity: 0.9;
        }
      }
    }

    .article-text .mol-products-sticky-bar {
      width: 470px;
    }

    .wide .mol-products-sticky-bar {
      width: 636px;
    }

    .extraWide .mol-products-sticky-bar {
      width: 964px;
    }
  }

  // sticky bar on mobile
  .mol-mobile .mol-products-sticky-bar {
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.15);
    left: 0;
    right: 0;
    justify-content: center;
    height: 60px;

    .mol-product-sticky-cta-wrapper {
      margin: 10px;

      .mol-product-cta {
        font-size: 14px;
      }
    }
  }
}
