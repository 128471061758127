:global {
  .mol-desktop {
    .mol-factbox[data-version="2"] {
      &.halfLHS,
      &.halfRHS {
        clear: none;
        width: calc(50% - 8px);
      }

      &.halfLHS {
        float: left;
        margin-right: 16px;
        margin-left: 0;
      }

      &.halfRHS {
        float: right;
        margin-left: 16px;
        margin-right: 0;
      }
    }

    .mol-factbox[data-version="2"].halfLHS + .mol-factbox[data-version="2"].halfRHS {
      margin-left: 0;
    }

    .mol-factbox[data-version="2"].halfLHS + .mol-factbox[data-version="2"].halfLHS {
      margin-right: 0;
    }

    .wide.permabox {
      .mol-factbox[data-version="2"] {
        &.halfLHS,
        &.halfRHS {
          margin: 0;
          margin-bottom: 16px;
          width: 100%;
          padding-left: 82px;
          padding-right: 82px;
        }
      }
    }
  }
}
