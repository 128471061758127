:global(body.mol-mobile) {
  :global(.mol-factbox[data-version="2"]) {
    background-color: rgba(var(--channel-color-rgb), 0.1);
    border-top: 1px solid var(--channel-color);
    border-bottom: 1px solid var(--channel-color);
    border-radius: 16px;
    max-width: 640px;
    margin: 0 auto;
    padding: 24px 16px;
    overflow: hidden;
    position: relative;
    margin-bottom: 16px;

    :global(.mol-factbox-title) {
      color: #000;
    }

    &:has(:global(.mol-fe-factbox-read-more-container)) {
      max-height: 460px;
    }

    :global(.mol-fe-factbox-read-more-container) {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 9;
      left: 0;
      bottom: 0;
      height: 120px;
      width: 100%;
      background: linear-gradient(180deg, rgba(255, 240, 229, 0) 0%, rgba(var(--channel-color-rgb), 0.1) 51.56%);

      :global(.mol-fe-factbox-read-more-btn) {
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 30px 10px 15px;
        border-radius: 999px;
        gap: 4px;
        border: 1px solid #ccc;
        background-color: #fff;
        height: 35px;
        margin-top: 32px;

        :global(.mol-fe-factbox-read-more-btn-text) {
          text-align: center;
          line-height: 100%;
          font-size: 14px;
          font-weight: 500;
          color: #004db3;
        }

        :global(.mol-fe-factbox-read-more-btn-caret) {
          position: absolute;
          right: 0;
          top: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 8px;
          width: 14px;
          height: 14px;
          opacity: 0.8;
          border: none;
        }
      }
    }

    :global(.mol-factbox-title),
    :global(.imageCaption),
    figcaption {
      border-bottom: none;
    }
  }
}
