:global {
  .permabox {
    .permabox-articles {
      --headline-badge-height: 16px;

      > h2 {
        background: #e3e3e3;
        padding: 6px;
        line-height: 1;
        text-transform: uppercase;
        font-weight: bold;
        font-family: var(--mol-default-font-family);
        font-size: 17px;
      }

      > [data-id] {
        position: relative;
        min-height: 115px;
        margin-bottom: 16px;
        padding-left: 170px;

        &:not(:last-of-type) {
          border-bottom: 1px solid #c0c0c0;
          padding-bottom: 16px;
        }

        h3 {
          background: none;
          padding: 0;
          margin: 0 0 6px;
          font-size: 18px;
          line-height: 1.3;
          font-weight: bold;
        }

        img {
          width: 154px;
          height: 115px;
          position: absolute;
          top: 0;
          left: 0;
        }

        a {
          color: #004db3;
        }

        p {
          font-size: 14px;
          line-height: 1.3;
        }
      }
    }

    .mol-factbox .sponsored-by {
      color: #000;
    }
  }

  .mol-desktop {
    .alpha > .permabox .mol-factbox {
      &.floatLHS,
      &.floatRHS {
        padding: 0 178px;
        padding-top: 12px;
        box-sizing: border-box;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }

    .permabox .mol-factbox[data-version="2"] {
      &.halfLHS,
      &.halfRHS,
      &.floatRHS,
      &.floatLHS {
        float: none;
      }
    }
  }

  .mol-mobile {
    .permabox {
      margin-top: 16px;

      h1 {
        font-family: var(--mol-default-font-family);
      }

      .permabox-articles {
        h3 {
          font-size: 14px;
        }

        > h2 {
          padding: 6px 8px;
          font-size: 16px;
          line-height: 1.3;
        }

        > [data-id] {
          &:not(:last-of-type) {
            border-bottom: 0;
            padding-bottom: 0;
          }

          h3 a > span {
            -webkit-line-clamp: 6;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          p {
            display: none;
          }
        }
      }
    }
  }
}
