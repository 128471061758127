.slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.contentWrapper {
  width: calc(100% - 320px);
  max-width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.galleryInfo {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.shareBar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shareBarLabel {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-right: 10px;
}

.articleTitle {
  font-size: 14px;
  color: #fff;
  max-width: calc(100% - 300px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 2px;
}

.shareButtons {
  display: flex;
  flex-direction: row;

  button {
    appearance: none;
    width: 18px;
    height: 20px;
    cursor: pointer;
    display: block;
    border: none;
    background-color: transparent;
    padding: 0;
    outline: none;
    background-size: 18px 18px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }

    &:not(:first-child) {
      margin-left: 5px;
    }

    &.facebookButton {
      background-image: url("./assets/facebook.svg");
    }

    &.twitterButton {
      background-image: url("./assets/twitter-outlined.svg");
      background-position-y: 1px;
    }

    &.pinterestButton {
      background-image: url("./assets/pinterest.svg");
    }

    &.emailButton {
      background-image: url("./assets/email.svg");
    }
  }
}

.mobileMode {
  .contentWrapper {
    width: 100%;
    padding: 20px 10px;
    background: #2c2c2c;
  }
}

@media screen and (min-width: 1800px) {
  .shareBarLabel {
    font-size: 22px;
    margin-right: 16px;
  }

  .shareButtons {
    button {
      width: 26px;
      height: 26px;
      background-size: 26px 26px;
    }
  }

  .articleTitle {
    max-width: calc(100% - 380px);
    font-size: 20px;
  }
}
