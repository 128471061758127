@use '../layout';

.container {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  border: none;
  transform: scale(1) !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  top: 0 !important;
  left: 0 !important;
  color: #fff;
  z-index: 2147483647;
  -ms-overflow-style: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
}

.body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  composes: contentContainer;
  flex: 1;
  font-weight: 600;
  padding-top: 76px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.6px;
  min-height: 140px;
}

.headingInfo {
  border-bottom: 1px solid #999;
  text-transform: uppercase;
  padding-bottom: 13px;
}

.headingTitle {
  margin-top: 10px;
  text-align: left;
  font-size: 36px;
  line-height: 40px;
}

.socialButtons {
  flex: 1;
  width: 100%;
  min-height: 100px;
}

.recommendations {
  width: 100%;
}

:global(.mol-desktop) {
  .container {
    padding-right: 20px;
  }
}

:global(.mol-mobile) {
  .socialButtons {
    min-height: 150px;
    flex: 3;
  }

  .heading {
    width: 100%;
    padding-top: 38px;
    min-height: 70px;
  }

  .headingTitle {
    display: none;
  }

  .headingInfo {
    text-align: center;
    font-size: 14px;
    border: 0;
  }
}
