$colourBlack: #000;
$colourWhite: #fff;
$colourYellow: #ff0;
$colourDarkGrey: #333;
$colourGrey: #666;
$colourBlackOpacity04: rgba(0, 0, 0, 0.4);
$colourWhiteOpacity05: rgba(255, 255, 255, 0.5);

:global {
  #mobileGallerySlider {
    overflow: hidden;
    visibility: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;

    .swipe-wrap {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;
    }

    .swipe-wrap > div {
      float: left;
      width: 100%;
      position: relative;
    }

    .slide-container {
      width: 100%;
      height: 100%;
      text-align: center;
      position: absolute;

      > img.blkBorder {
        border-top: 0;
        border-bottom: 0;
      }

      .vjs-video-container::before {
        padding-bottom: 86px;
      }

      .vjs-responsive {
        &.vjs-video-container {
          object,
          video {
            height: calc(100% - 86px);
            top: 36px;
            bottom: 37px;
          }
        }

        .vjs-poster {
          background-position: 50% calc(50% - 0%);
        }

        .vjs-big-play-button {
          top: 40px;
        }
      }
    }

    .image .slide {
      position: absolute;
      top: 50%;
      left: 50%;
      border-left: 1px solid $colourBlack;
      border-right: 1px solid $colourBlack;
    }

    .ad-holder > iframe {
      width: 768px;
      height: 1024px;
      border: 0;
      overflow: hidden;
      z-index: 0 !important;
    }

    .ad-guard {
      background-color: transparent;
      position: absolute;
      opacity: 0.2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .mobile-gallery-bar {
      font-family: var(--mol-default-font-family);
      font-weight: bold;
      z-index: 1000;
      position: fixed;
      width: 100%;
      background-color: $colourBlack;
      opacity: 0.8;
      color: $colourWhite;
      text-align: center;
      display: block;
    }

    .top-bar {
      top: 0;
    }

    .top-bar .advert {
      color: $colourYellow;
    }

    .top-bar .close-button {
      z-index: 2000;
      background-image: url('//i.dailymail.co.uk/i/furniture/mobile/66close.png');
      background-repeat: no-repeat;
      background-size: contain;
      top: 0;
      right: 0;
      margin: 5px;
      position: fixed;
      cursor: pointer;
    }

    .navigation-bar {
      bottom: 0;
    }

    .navigation-arrow {
      height: 100%;
      position: absolute;
      cursor: pointer;
    }

    .navigation-arrow.left,
    .navigation-arrow.right {
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center;
    }

    .navigation-arrow.right {
      background-image: url('//i.dailymail.co.uk/i/furniture/mobile/66next.png');
    }

    .navigation-arrow.left {
      background-image: url('//i.dailymail.co.uk/i/furniture/mobile/66previous.png');
    }

    &.locked .navigation-arrow,
    &.first .navigation-arrow.left,
    &.last .navigation-arrow.right {
      opacity: 0.3;
    }

    &.image.no-furniture .mobile-gallery-bar {
      display: none;
    }

    &.image.no-furniture .caption-bar {
      bottom: 0;
    }

    &.image.data-share-slide {
      .caption-bar {
        display: none;
      }

      .mobile-gallery-bar {
        display: block !important;

        &.top-bar {
          background: none;

          .title {
            display: none;
          }
        }
      }
    }

    .caption-bar {
      position: fixed;
      color: $colourWhite;
      font-family: var(--mol-default-font-family);
      font-weight: bold;
      width: 100%;
      padding: 10px 0;
      background-color: $colourBlack;
      opacity: 0.8;
      text-align: center;
      display: block;
    }

    .caption-bar.empty {
      display: none !important;
    }

    .caption-bar .text {
      width: 95%;
      margin: auto;
    }

    .adLinkLabel {
      z-index: 10010;
      position: absolute;
      background-color: $colourBlack;
      font-family: var(--mol-default-font-family);
      font-weight: bold;
      text-align: center;
      display: block;
      border: 2px solid $colourWhite;
      opacity: 0.8;
      border-radius: 10px;
    }

    .adLinkLabel a {
      display: block;
      color: $colourWhite;
    }

    .adLinkLabel a:hover {
      color: $colourWhite;
    }

    .adLinkLabel a:visited {
      color: $colourWhite;
    }

    .slide-container .pinchzoom-item,
    .mol-mobile & .vjs-video-container {
      background: transparent url('//i.dailymail.co.uk/i/furniture/fff/preload-inline-black.gif') no-repeat center center;
    }

    .mol-mobile & .vjs-video-container {
      background-color: transparent;
    }
  }

  #mobileGalleryModal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 10000;
    background-color: $colourBlack;
    touch-action: pan-x;

    .canvas {
      position: fixed;
      top: -100%;
      left: -100%;
      height: 600%;
      width: 600%;
      background-color: $colourBlack;
    }
  }

  @media screen and (orientation: landscape) {
    #mobileGallerySlider {
      .interstitial_portrait {
        display: none;
      }

      .interstitial_landscape {
        display: block;
      }

      .navigation-arrow.left {
        left: 41%;
      }

      .navigation-arrow.right {
        left: 59%;
      }
    }

    .mol-desktop {
      #mobileGallerySlider {
        .mobile-gallery-bar {
          height: 44px;
          line-height: 44px;
          font-size: 14pt;
        }

        .mobile-gallery-bar .navigation-arrow {
          width: 44px;
          margin-left: -22px;
        }

        .caption-bar {
          bottom: 44px;
          font-size: 12pt;
        }

        .adLinkLabel {
          bottom: 100px;
          right: 30px;

          a {
            min-width: 240px;
            padding: 0 20px;
            height: 80px;
            line-height: 80px;
            font-size: 24px;
          }
        }
      }

      .mobile-gallery-bar .close-button {
        height: 34px;
        width: 34px;
      }
    }

    .mol-mobile {
      #mobileGallerySlider {
        .mobile-gallery-bar .navigation-arrow {
          margin-left: -16px;
        }

        .adLinkLabel {
          bottom: 50px;
          right: 20px;

          a {
            padding: 10px;
            font-size: 14px;
          }
        }

        .interstitial_landscape img {
          height: 249px;
          width: auto;
        }
      }
    }
  }

  @media screen and (orientation: portrait) {
    #mobileGallerySlider {
      .navigation-arrow {
        margin-left: -33px;
      }

      .interstitial_portrait {
        display: block;
      }

      .interstitial_landscape {
        display: none;
      }
    }

    .mol-desktop {
      #mobileGallerySlider {
        .mobile-gallery-bar {
          height: 66px;
          line-height: 66px;
          font-size: 20pt;
        }

        .navigation-arrow {
          width: 66px;

          &.left {
            left: 38%;
          }

          &.right {
            left: 62%;
          }
        }

        .mobile-gallery-bar .close-button {
          height: 56px;
          width: 56px;
        }

        .caption-bar {
          bottom: 66px;
          font-size: 14pt;
        }

        .adLinkLabel {
          bottom: 200px;
          right: 30px;

          a {
            min-width: 310px;
            padding: 0 20px;
            height: 100px;
            line-height: 100px;
            font-size: 30px;
          }
        }
      }
    }

    .mol-mobile {
      #mobileGallerySlider {
        .adLinkLabel {
          bottom: 50px;
          right: 20px;

          a {
            padding: 10px;
            font-size: 14px;
          }
        }

        .interstitial_portrait img {
          width: 100%;
          height: auto;
        }

        .navigation-arrow {
          &.left {
            left: 42%;
          }

          &.right {
            left: 68%;
          }
        }
      }
    }
  }

  .mol-mobile {
    .mobile-gallery {
      bottom: 10px;
    }

    #mobileGallerySlider .swipe-wrap > div {
      overflow: hidden; // this fixes an issue on advertising
    }

    #mobileGallerySlider .mobile-gallery-bar {
      height: 34px;
      line-height: 34px;
      font-size: 16px;
    }

    #mobileGallerySlider .top-bar .close-button {
      height: 26px;
      width: 26px;
    }

    #mobileGallerySlider .navigation-arrow {
      width: 32px;
    }

    #mobileGallerySlider .caption-bar {
      bottom: 32px;
      font-size: 14px;
      padding: 12px 0;
      font-weight: normal;
    }
  }

  .mobile-gallery-instruction {
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 14px;
    top: 50%;
    margin-top: -75px;
  }

  .mobile-gallery-instruction-wrapper {
    padding: 20px;
    margin: auto;
    background-color: $colourBlackOpacity04;
    max-width: 300px;
  }

  .mobile-gallery-icon-big {
    background: url('//i.dailymail.co.uk/i/furniture/mobile/icon_camera_90x68.png') no-repeat 0 0;
    background-size: 50px;
    width: 50px;
    height: 50px;
    margin: auto;
  }

  .mobile-gallery-instruction-text {
    color: $colourWhite;
    max-width: 250px;
    margin: auto;
  }

  .mobile-gallery-instruction-button {
    border: solid $colourWhite 1px;
    border-radius: 2px;
    color: $colourWhite;
    padding: 6px;
    width: 70px;
    margin: 12px auto 0;
    font-weight: bold;
    cursor: pointer;
  }

  .mobile-gallery-instruction-button:hover {
    box-shadow: 0 0 5px 0 $colourWhite;
    background-color: $colourWhiteOpacity05;
  }

  @media screen and (max-width: 768px) {
    .mobile-gallery-icon-big {
      background-size: 32px;
      width: 32px;
      height: 32px;
      float: left;
      margin: 2px 6px 0 0;
    }

    .mobile-gallery-instruction {
      margin-top: 0;
      top: 3%;
    }

    .mobile-gallery-instruction-text {
      text-align: left;
    }

    .mobile-gallery-instruction-wrapper {
      padding: 10px;
    }
  }

  .pinchzoom-item {
    height: 100%;
    width: 100%;
  }

  .mobile-gallery-open .page-banner {
    padding-top: 0 !important;
  }

  .mobile-gallery-open .fff-hover-icon {
    display: none;
  }

  .overlay-icon {
    background-position: center left;
    background-repeat: no-repeat;
    background-color: $colourBlackOpacity04;
    border-radius: 3px;
    height: 36px;
    line-height: 60px;
    font-size: 18px;
    font-family: var(--mol-default-font-family);
    color: $colourWhite;
    position: absolute;
    text-align: center;
  }

  .overlay-icon ul {
    width: 40px;
    margin: 0;
  }

  .mobile-gallery {
    right: 10px;
    box-sizing: border-box;
    display: table;
    height: 36px;
    line-height: 34px;
    font-size: 16px;
    text-align: left;
    bottom: 11px;

    span {
      padding-right: 10px;
      padding-top: 2px;
      float: none;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .mobile-gallery-icon {
    background: url('//i.dailymail.co.uk/i/furniture/mobile/icon_camera_90x68.png') no-repeat 10px;
    height: 20px;
    width: 24px;
    float: none;
    display: table-cell;
    vertical-align: middle;
    padding-top: 7px;
    padding-right: 10px;
    padding-left: 10px;
    background-size: 27px;
    box-sizing: content-box;
  }

  .image-wrap.fff-pic .fff-hover-icon {
    top: inherit !important;
    bottom: 24px;
  }

  .zoomed .mobile-gallery {
    display: none;
  }

  .advert-hidden {
    visibility: hidden;
  }
}
