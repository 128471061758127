.container {
  background-color: #353535;
  padding: 10px;
  padding-bottom: 0;
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;

  &.half {
    width: calc(50% + 4px);
  }
}

.heading {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.items {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-flow: row wrap;
}

.item {
  display: block;
  width: calc(25% - 8px);
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 10px;

  .half & {
    width: calc(50% - 5px);
  }

  &:hover {
    .headline {
      text-decoration: underline;
    }
  }
}

.imageStrip {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3px;
}

.itemImage {
  width: calc(35% - 2px);
}

.itemImageCount {
  width: calc(30% - 2px);
  font-size: 11px;
  font-weight: 600;
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: stretch;
  flex-direction: column;
  text-align: center;
}

.headline {
  font-size: 13px;
  line-height: 1.1;
  color: #fff;
  width: 100%;
  margin-top: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 46px;
}

.mobileMode {
  &.container {
    background: none;
  }

  .heading {
    padding-left: 30px;
    background-image: url("./assets/camera.svg");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 20px 15px;
    margin-bottom: 20px;
  }

  .item {
    width: calc(50% - 10px);
  }
}

@media screen and (min-width: 1800px) {
  .container {
    padding: 20px;
    padding-bottom: 0;
    margin-top: 20px;
  }

  .item {
    margin-bottom: 16px;
    width: calc(25% - 12px);
  }

  .itemImageCount {
    font-size: 18px;
  }

  .heading {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .headline {
    font-size: 18px;
    max-height: 60px;
    margin-top: 10px;
  }
}

@media (orientation: landscape) {
  .mobileMode {
    &.container {
      width: 40%;
      margin: auto;
      margin-top: 0;
    }
  }
}
