:global {
  body.enhanced-gallery-open {
    overflow: hidden;

    #chromelessPlayer {
      z-index: 999999999;
    }

    #top {
      z-index: 1;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  z-index: 99999999;
  font-family: var(--mol-default-font-family);
  touch-action: pan-y;
}

.disabled {
  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #999;
    opacity: 0.5;
  }
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background: #000;
  border-bottom: 1px solid #333;
  flex-shrink: 0;
  touch-action: none;
}

.close {
  appearance: none;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 40px;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  background-image: url("./assets/close.svg");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 13px 13px;
  padding-left: 10px;
  padding-right: 30px;
  font-size: 15px;
  outline: none;
  color: #fff;
  border: 0;
  font-weight: 600;
}

.nextGalleryButton,
.previousGalleryButton {
  appearance: none;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 40px;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat;
  padding: 0;
  outline: none;
  color: #fff;
  font-size: 15px;
  border: 0;
  font-weight: 600;

  &[disabled] {
    opacity: 0.7;
  }
}

.nextGalleryButton {
  background-image: url("./assets/next.svg");
  background-size: 9px 15px;
  background-position: right 8px center;
  padding-left: 8px;
  padding-right: 26px;
}

.previousGalleryButton {
  background-image: url("./assets/previous.svg");
  background-size: 9px 15px;
  background-position: left 8px center;
  padding-left: 26px;
  padding-right: 8px;
}

.scroller {
  overflow-y: scroll;
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 20px;
  background: #1a1a1a;
  touch-action: pan-y;
}

.headingWrapper {
  margin-bottom: 20px;
}

.heading {
  font-size: 16px;
  line-height: 1.25;
  color: #fff;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 600;
  overflow: hidden;
}

.readMoreLink {
  display: block;
  text-decoration: underline;
  color: #fff;
  margin-top: 6px;
  font-size: 15px;
  font-weight: normal;
}

.mpuWrapper {
  width: calc(100% + 20px);
  margin: 0 -10px;
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 250px;
  padding: 10px 0;
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
  position: relative;

  &::after {
    display: block;
    content: "Advertisement";
    position: absolute;
    top: -24px;
    left: 10px;
    font-size: 13px;
    color: #fff;
  }
}

.bannerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 50px;
  background: #000;
  flex-shrink: 0;
  touch-action: none;
}

@media screen and (max-width: 340px) {
  .close {
    background-position: right 6px center;
    background-size: 12px 12px;
    padding-left: 10px;
    padding-right: 26px;
    font-size: 14px;
  }

  .nextGalleryButton {
    font-size: 14px;
    background-size: 8px 13px;
    background-position: right 6px center;
    padding-left: 6px;
    padding-right: 22px;
  }

  .previousGalleryButton {
    font-size: 14px;
    background-size: 8px 13px;
    background-position: left 6px center;
    padding-left: 22px;
    padding-right: 6px;
  }
}

@media screen and (orientation: landscape) {
  .scroller {
    padding-left: calc((100% - 400px) / 2);
    padding-right: calc((100% - 400px) / 2);
  }
}
