:global {
  body.enhanced-gallery-open {
    overflow: hidden;

    #chromelessPlayer {
      z-index: 999999999;
    }

    #top {
      z-index: 1;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: #000;
  z-index: 99999999;
  font-family: var(--mol-default-font-family);
  touch-action: none;
}

.disabled {
  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #999;
    opacity: 0.5;
  }
}

.galleryWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: none;

  .bigSky & {
    width: calc(100% - 600px - 40px);
    left: 320px;
  }

  .smallSky & {
    width: calc(100% - 320px - 40px);
    left: 180px;
  }

  .bigBillboard & {
    height: calc(100% - 250px - 20px);
  }

  .smallBillboard & {
    height: calc(100% - 90px - 20px);
  }

  .adFree & {
    width: 100%;
    height: 100%;
    left: 0;
  }
}

.bannerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 50px;
  background: #000;
  flex-shrink: 0;
}

.mobileMode {
  &.overlay {
    display: flex;
    flex-direction: column;
  }

  .galleryWrapper {
    flex-grow: 1;
    height: 1px;
    position: relative;
  }
}
