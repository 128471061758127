:global {
  .mol-factbox[data-version=''],
  .mol-factbox:not([data-version]) {
    margin: 0 0 16px;

    .mol-video {
      position: relative;
      left: -5px;
    }

    :global(.aileron-font .article-text) & {
      margin-bottom: 16px;

      h3.wocc {
        font-size: 20px;
        line-height: 24px;
      }
    }

    :global(.aileron-font .article-text.extraWide) & h3.wocc {
      font-size: 22px;
      line-height: 26px;
    }

    .article-text .art-ins .artSplitter .imageCaption {
      width: 616px;
    }

    .article-text .art-ins .thinCenter .imageCaption {
      width: 450px;
    }

    .float-l {
      padding-right: 8px;
    }

    .float-r {
      padding-left: 8px;
    }

    .mol-img-group.float-l,
    .mol-img-group.float-r {
      box-sizing: border-box;
      width: 50%;
    }

    &.floatLHS {
      margin-right: 10px;
    }

    &.floatRHS {
      margin-left: 20px;
    }

    &.floatLHS .ins .artSplitter,
    &.floatLHS .ins .artSplitter .imageCaption,
    &.floatRHS .ins .artSplitter,
    &.floatRHS .ins .artSplitter .imageCaption {
      width: auto;
    }

    :global(.article-text) & {
      clear: both;

      &.floatLHS,
      &.floatRHS {
        clear: none;
      }
    }

    :global(.extraWide) & {
      .xwArtSplitter {
        .third {
          margin-left: 9px;
          width: 310px;
        }

        .first.third {
          margin-left: 0 !important;
        }

        .quarter {
          margin-left: 10px;
          width: 229px;
        }

        .first.quarter {
          margin-left: 0 !important;
        }
      }

      .thinArtSplitter {
        .splitLeft,
        .splitRight {
          width: 223px;
        }

        .splitLeft {
          margin-left: 0;
        }

        .splitRight {
          margin-left: 10px;
        }

        .third {
          margin-left: 10px;
          width: 144px;
        }

        .first.third {
          margin-left: 0 !important;
        }

        .quarter {
          margin-left: 12px;
          width: 104px;
        }

        .first.quarter {
          margin-left: 0 !important;
        }
      }
    }

    :global(.wide) & .artSplitter {
      .third {
        margin-left: 10px;
        width: 200px;
      }

      .first.third {
        margin-left: 0 !important;
      }

      .quarter {
        margin-left: 10px;
        width: 147px;
      }

      .first.quarter {
        margin-left: 0 !important;
      }
    }
  }
}
