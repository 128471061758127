:global {
  #article-summary-block {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 10px 4px;
    background: #191919;
    position: fixed;
    top: 0;
    z-index: 1001;
    width: calc(100% - 2rem);
    box-sizing: border-box;
  }

  #article-summary-text {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #article-summary-close-btn {
    cursor: pointer;
    background: transparent;
    padding: 12px;
    border-radius: 999px;
    border: 1px solid #ccc;
    margin-left: auto;
  }

  #article-summary-image {
    width: 134px;
    height: 100px;
    object-fit: cover;
  }

  #comments-overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    opacity: 1;
    background: rgba(0, 0, 0, 0);
    left: 0;
    top: 0;
    height: 100vh;
    min-height: -webkit-fill-available;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4);
    transition: transform 0.5s ease-out;
    z-index: 10002;
  }

  #comments-overlay-content {
    position: relative;
    background: rgba(255, 255, 255, 1);
    overflow-y: auto;
    width: 100%;
    height: 100%;
    min-height: -webkit-fill-available;
    left: 0;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  #reader-comments-in-overlay {
    display: flow-root;
  }

  #sticky-comments-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1002;
    margin-bottom: 15px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  }
}
