.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  cursor: pointer;
  display: block;
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  outline: none;
  background-image: url("./assets/close.svg");
  background-size: 55%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

.nextButton,
.previousButton {
  width: 44px;
  height: 44px;
  cursor: pointer;
  display: block;
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  outline: none;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 10%;
  opacity: 0.8;

  &:not(:disabled):hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.nextButton {
  background-image: url("./assets/next.png");
}

.previousButton {
  background-image: url("./assets/previous.png");
}

.topBar,
.bottomBar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
}

.counter {
  font-weight: 700;
  font-size: 18px;
}

.bottomBar {
  bottom: 0;
  left: 0;
  flex-direction: column;
  z-index: 100000000;
}

.paginationButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.articleTitle,
.caption {
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  text-align: center;
  width: 100%;
  color: #fff;
  font-weight: 600;
  line-height: 1.25;
}

.articleTitle {
  padding: 15px;
  position: relative;

  .hasCaption &::after {
    position: absolute;
    display: block;
    content: "";
    bottom: 0;
    left: 33%;
    right: 33%;
    height: 1px;
    background: #fff;
  }
}

.topBar {
  top: 0;
  left: 0;
  height: 44px;
}

.mobileMode {
  &.closeButton {
    width: 34px;
    height: 34px;
    opacity: 1;
    z-index: 100000000;
  }

  &.topBar {
    height: 34px;
    z-index: 99999999;
  }

  &.bottomBar {
    z-index: 99999999;
  }

  .counter {
    font-size: 16px;
  }

  .articleTitle,
  .caption {
    font-size: 12px;
  }

  .nextButton,
  .previousButton {
    width: 34px;
    height: 34px;
  }
}
