.videoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: calc(16 / 9 * 55vh);

  &.mobileMode {
    width: 100%;
  }

  > div {
    width: 100%;
  }

  :global(.vjs-video-container) {
    max-width: 100%;
  }
}

@media screen and (min-width: 1500px) and (min-height: 600px) and (max-height: 700px) {
  .videoWrapper:not(.mobileMode) {
    max-width: calc(16 / 9 * 45vh);
  }
}
