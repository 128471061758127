:global {
  .mol-products-module {
    clear: both;
    border-top: 1px solid #e3e3e3;
    margin-bottom: 8px;

    .mol-product {
      border-bottom: 1px solid #e3e3e3;
      padding: 24px 0;
      position: relative;

      &.hide-cta {
        .mol-product-cta {
          display: none;
        }
      }
    }

    .mol-product-header,
    .mol-product-cta,
    .mol-product-footer {
      font-weight: bold;
    }

    .mol-product-header {
      display: block;
      font-size: 18px;
      line-height: 1.3em;
      color: #0a50b0;
      background: none;
      margin-bottom: 14px;

      &:hover {
        text-decoration: none;
      }
    }

    .mol-product-cta {
      appearance: none;
      border: none;
      padding: 0 26px;
      position: absolute;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      border-radius: 2px;

      &:hover {
        text-decoration: none;
      }
    }

    .mol-product-footer {
      position: absolute;
      display: inline-block;
      padding: 10px 0;
      font-size: 24px;
      line-height: 0.7;
      color: #000;

      &:hover {
        text-decoration: none;
      }
    }

    .mol-product-body {
      font-size: 14px;
      line-height: 1.2;
      color: #000;
      margin-bottom: 14px;
    }

    .mol-product-body p,
    .mol-product-body li {
      font-size: 14px;
      line-height: 1.2;
    }

    .mol-product-image {
      margin: 0;
    }

    &.one-up {
      width: 636px;

      .mol-product {
        width: 100%;
      }

      .mol-product-body,
      .mol-product-header {
        float: left;
        width: 50%;
      }

      .mol-product-body {
        margin-bottom: 48px;
      }

      .mol-product-image {
        width: 298px;
        padding-right: 20px;
        float: left;
      }

      .mol-product-cta {
        bottom: 24px;
        right: 0;
      }

      .mol-product-footer {
        bottom: 20px;
        left: 318px;
      }
    }

    &.three-up,
    &.two-up {
      > * {
        display: flex;
        flex-flow: row wrap;
      }

      .mol-product {
        box-sizing: border-box;
        border-left: 1px solid #ccc;
        padding: 12px 12px 52px;

        &.hide-cta {
          padding-bottom: 0;

          .mol-product-footer {
            position: static;
            padding: 0 0 10px;
          }
        }
      }

      .mol-product-footer {
        bottom: 12px;
        left: 12px;
      }

      .mol-product-cta {
        bottom: 12px;
        right: 12px;
      }

      .mol-product-image {
        width: 298px;
        margin-bottom: 12px;
      }
    }

    &.three-up {
      width: 964px;

      .mol-product {
        width: 321px;

        &:nth-child(n + 1):nth-child(3n + 1) {
          border-left: none;
        }
      }
    }

    &.two-up {
      width: 636px;

      .mol-product {
        width: 318px;

        &:nth-child(2n + 1) {
          border-left: none;
        }
      }
    }

    &.one-up-big {
      border-top: none;

      .mol-product {
        padding: 8px 0 0;
      }

      .mol-product-header {
        font-size: 24px;
        line-height: 1.1em;
      }

      .mol-product-cta,
      .mol-product-footer {
        display: inline-block;
        position: relative;
        vertical-align: middle;
      }

      .mol-product-footer {
        margin-right: 10px;
        top: 2px;
        line-height: 1;
      }

      .mol-product-body {
        margin-top: 14px;
      }

      .mol-product-footer-wrap {
        float: right;
        padding: 10px 0 10px 10px;
        border-left: 1px solid #ccc;
        margin-top: 20px;
        margin-left: 10px;
      }

      .hide-cta {
        .mol-product-header {
          width: 500px;
          font-size: 20px;
          line-height: 1.1em;
        }

        .mol-product-footer-wrap {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0;
          float: none;
          padding: 0 10px;
        }

        .mol-product-footer {
          margin-right: 0;
          line-height: 0.8em;
        }
      }
    }
  }

  .mol-mobile {
    .mol-products-module {
      width: 100%;

      .mol-product {
        width: 100%;
        border-left: none;
        border-right: none;
        padding: 20px 0 62px;
      }

      .mol-product-image {
        width: 100%;
        padding-right: 0;
      }

      .mol-product-cta {
        bottom: 20px;
      }

      .mol-product-footer {
        bottom: 20px;
        left: 0;
      }

      figure figcaption {
        display: none;
      }

      figure {
        margin: 0;
      }

      .mol-product-body {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .mol-product-body,
      .mol-product-header {
        float: none;
        width: 100%;
      }

      &.one-up {
        .mol-product {
          display: flex;
          flex-direction: column;
        }

        .mol-product-image {
          order: 2;
        }

        .mol-product-header {
          order: 1;
        }

        .mol-product-body {
          order: 3;
          margin-top: 12px;
        }
      }

      &.one-up-big {
        .mol-product {
          display: flex;
          flex-direction: column;
          padding-bottom: 10px;
        }

        .mol-product-image {
          order: 2;
        }

        .mol-product-header {
          order: 1;
          font-size: 18px;
        }

        .hide-cta .mol-product-header {
          width: 100%;
        }

        .mol-product-footer-wrap {
          order: 4;
          position: static;
          border: none;
          margin: 0;
          padding: 0;
        }

        .mol-product-footer {
          float: left;
          position: static;
        }

        .mol-product-cta {
          float: right;
          position: static;
        }

        .mol-product-body {
          order: 3;
        }
      }
    }
  }
}
