:global {
  .pinchzoom-container {
    overflow: hidden;
  }

  .pinchzoom-item * {
    /* For Opera and <= IE9, we need to add unselectable="on" attribute onto each element */

    /* Check this site for more details: http://help.dottoro.com/lhwdpnva.php */
    user-select: none;
    -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
    // stylelint-disable-next-line property-no-unknown
    user-drag: none;
  }

  .pinchzoom-item {
    transform-origin: 0 0;
  }

  .pinchzoom-container.pinchzoom-on .pinchzoom-item {
    transform: translate3d(0, 0, 0);
    perspective: 1000;
    backface-visibility: hidden;
  }
}
