@use '../layout';

.container {
  flex: 1;
  background: rgba(63, 63, 63, 0.8);
}

.body {
  composes: contentContainer;
  flex: 1;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

.heading {
  font-weight: 600;
  padding-top: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  padding-bottom: 17px;
  flex: none;

  &::after {
    content: '';
    display: inline-block;
    right: 0;
    width: 20px;
    height: 15px;
    background: url('../../assets/camera-icon.svg') no-repeat;
    background-size: cover;
    margin-left: 7px;
  }
}

.items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item {
  display: block;
  width: 23.5%;
  margin-right: 2%;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    .lightOverlay::after {
      visibility: visible;
    }

    .headline {
      color: #e3e3e3;
    }
  }
}

.itemImage {
  composes: lightOverlay;
}

.itemImageCount {
  font-family: var(--mol-default-font-family);
  composes: lightOverlay;
  font-size: 16px;
  font-weight: 700;
  background: #000;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 30%;
}

.topSection {
  width: 100%;
  margin-bottom: 1%;

  .itemImage {
    width: 100%;
  }
}

.bottomSection {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .itemImage {
    width: 34%;
  }
}

.headline {
  margin-top: 5px;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  max-height: 100px;
  overflow: hidden;
}

:global(.mol-mobile) {
  .body {
    width: 100%;
    padding: 0 16px 47px;
  }

  .headline {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.3px;
    max-height: 90px;
  }

  .heading {
    font-size: 14px;
    line-height: 17px;
  }

  .items .item {
    width: 48.5%;
    margin-right: 5%;

    &:nth-child(2) {
      margin-right: 0;
    }

    &:nth-child(3),
    &:nth-child(4) {
      display: none;
    }
  }
}
