.slide {
  width: 100%;
  margin-bottom: 20px;
}

.placeholder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.info {
  background: #000;
  padding: 10px;
}

.caption {
  font-size: 13px;
  line-height: 1.3;
  color: #fff;
  margin-bottom: 10px;
}

.actionBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.counter {
  font-size: 16px;
  font-weight: 600;
  color: #999;
}

.shareButtons {
  display: flex;
  flex-direction: row;

  button {
    appearance: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: block;
    border: none;
    background-color: transparent;
    padding: 0;
    outline: none;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    &:not(:first-child) {
      margin-left: 15px;
    }

    &.facebookButton {
      background-image: url("./assets/facebook.svg");
    }

    &.twitterButton {
      background-image: url("./assets/twitter.svg");
      background-position-y: 1px;
    }

    &.pinterestButton {
      background-image: url("./assets/pinterest.svg");
    }

    &.emailButton {
      background-image: url("./assets/email.svg");
    }
  }
}

.closeButton {
  appearance: none;
  width: 100px;
  height: 24px;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  outline: none;
  opacity: 0.8;
  border: 1px solid #bbb;
  color: #bbb;
  border-radius: 1px;
  font-size: 13px;
}

@media screen and (max-width: 340px) {
  .shareButtons {
    button {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
