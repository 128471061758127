$iconSize: 40px;

.prevNextWrapper {
  position: fixed;
  top: calc(50% - 75px);
  z-index: 10000;
  padding: 15px;

  &.prev {
    left: 0;
    padding-left: 0;
  }

  &.next {
    right: 0;
    padding-right: 0;
  }
}

.prevNextButton {
  width: 40px;
  height: 136px;
  background: #fff;
  cursor: pointer;
  border: 1px solid #eee;
  position: relative;

  &::after {
    content: '';
    width: $iconSize;
    height: $iconSize;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-image: url('./assets/next.svg');
  }

  &:hover {
    background-color: #f4f4f4;
  }
}

.prevNextWrapper.prev .prevNextButton {
  border-left: 0;

  &::after {
    background-position: 0 0;
    transform: rotate(180deg);
  }
}

.prevNextWrapper.next .prevNextButton {
  border-right: 0;

  &::after {
    background-position: 100% 0;
  }
}

:global(.mobile-gallery-open) .wrapper,
:global(.enhanced-gallery-open) .wrapper {
  z-index: 1;
}
