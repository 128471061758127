.contentContainer {
  width: 964px;
  font-family: var(--mol-default-font-family);
}

.boxMaster {
  position: relative;
  overflow: hidden;
  padding: 0;
}

.boxWrapper {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.boxContent {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.lightOverlay {
  position: relative;

  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    visibility: hidden;
  }
}

@media only screen and (max-width: 965px) {
  :global(.mol-desktop) {
    .contentContainer {
      width: 920px;
    }
  }
}
