.slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.image.fitImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
