.adSlot {
  :global(.adHolder) {
    margin-top: 0 !important;
  }
}

.mpu,
.mpu .inner {
  width: 300px;
  height: 250px;
}

.billboard,
.billboard .inner {
  width: 728px;
  height: 90px;
}

.billboard.big,
.billboard.big .inner {
  width: 970px;
  height: 250px;
}

.billboard {
  position: absolute;
  bottom: 0;
  left: calc((100vw - 728px) / 2);
}

.billboard.big {
  left: calc((100vw - 970px) / 2);
}

.staticMobileBanner {
  position: relative;
}

.staticMobileBanner,
.staticMobileBanner .inner {
  width: 320px;
  height: 50px;
}

.skyScraperLeft,
.skyScraperRight,
.skyScraperLeft .inner,
.skyScraperRight .inner {
  width: 160px;
  height: 600px;
}

.skyScraperLeft.big,
.skyScraperRight.big,
.skyScraperLeft.big .inner,
.skyScraperRight.big .inner {
  width: 300px;
  height: 600px;
}

.skyScraperLeft,
.skyScraperRight {
  position: fixed;
  top: 0;
}

.skyScraperLeft {
  left: 0;
}

.skyScraperRight {
  right: 0;
}
