@use 'variables' as vars;

:global {
  .mol-factbox[data-version="2"] {
    figure {
      margin: 0 0 0.75 * vars.$baseLine;
    }

    img {
      border: 1px solid #e3e3e3;
    }

    .mol-factbox-body {
      .mol-img-group {
        font-size: 0;
        vertical-align: top;
        white-space: nowrap;
        width: auto;

        &.float-l {
          margin-right: vars.$baseLine;
        }

        &.float-r,
        .splitRight,
        .third,
        .quarter {
          margin-left: vars.$baseLine;
        }
      }

      .mol-img {
        white-space: normal;
      }

      .float-l,
      .float-r {
        width: 286px;

        :global(.gamma) & {
          width: 450px;
        }
      }

      .float-l,
      .float-r,
      .splitLeft,
      .splitRight,
      .third,
      .quarter {
        display: inline-block;
      }

      .splitLeft,
      .splitRight,
      .third,
      .quarter {
        float: none;
        width: auto;
      }
    }

    &.floatLHS {
      margin-right: vars.$baseLine;
    }

    &.floatRHS {
      margin-left: vars.$baseLine;
    }

    &.floatLHS,
    &.floatRHS {
      .mol-factbox-body {
        .mol-img-group.float-l,
        .mol-img-group.float-r {
          width: 8.125 * vars.$baseLine;
        }
      }
    }

    &.halfLHS,
    &.halfRHS {
      .mol-factbox-body {
        .mol-img-group.float-l,
        .mol-img-group.float-r {
          // as per https://github.com/MailOnline/cc-article-shared/blob/master/lib/components/modules/CMInsert2.js#L35
          width: 211px;
        }
      }
    }
  }

  .mol-desktop {
    .alpha,
    .gamma {
      .mol-factbox[data-version="2"] {
        .mol-img img {
          width: 100%;
          height: auto;
        }

        .mol-img-group {
          .splitLeft,
          .splitRight {
            float: left;
            width: calc(50% - 8px);
            display: block;
          }

          .third {
            width: calc(33.33% - 11px);
          }

          .quarter {
            width: calc(25% - 12px);
          }
        }
      }
    }
  }
}
