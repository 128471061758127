.scrollWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.scrollingElement {
  position: absolute;
  display: flex;
  flex-direction: row;
  transition: left 200ms ease-out;
  width: 100%;
  height: 100%;
}
