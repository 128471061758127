:global {
  :root {
    box-sizing: inherit;

    --mp-gradient-first: #182850;
    --mp-gradient-second: #013e90;
    --home-color: #004db3;
    --tim-color: #451344;
    --tim-gradient-first: #5c395a;
    --tim-gradient-second: #441243;
  }

  [data-related-story-factbox] {
    --preview-channel-color: var(--channel-color);
    --preview-text-color: var(--channel-color-light);

    box-sizing: border-box;

    &[data-channel-color='mailplus'] {
      --preview-channel-color: linear-gradient(32deg, var(--mp-gradient-first) 61.54%, var(--mp-gradient-second) 100%);
    }

    &[data-channel-color='thisismoney'] {
      --preview-channel-color: var(--tim-color);
    }

    &[data-channel-color='thisismoneyplus'] {
      /* stylelint-disable-next-line declaration-colon-newline-after */
      --preview-channel-color: linear-gradient(
        118deg,
        var(--tim-gradient-first) 26.13%,
        var(--tim-gradient-first) 26.13%,
        var(--tim-gradient-second) 64.88%
      );
    }

    .container {
      text-decoration: none !important;
      box-sizing: border-box;
      background: var(--preview-channel-color);
      padding: 20px;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-self: stretch;
      cursor: pointer;
      margin-bottom: 16px;

      &:hover {
        .headlineText {
          border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        }
      }
    }

    .readMore {
      color: white;
      font-family: var(--mol-default-font-family, Inter), Arial, Helvetica, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      align-self: stretch;
      text-decoration-line: underline;
      /* stylelint-disable-next-line property-no-unknown */
      text-underline-offset: 4px;
      margin: 0 0 10px;
    }

    .headline {
      color: #fff;
      font-family: var(--mol-default-font-family, Inter), Arial, Helvetica, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-wrap: pretty;
      margin: 0;
    }

    /* Mobile/Tablet styles */
    &[data-platform] {
      /* Tablet */
      .container {
        min-width: 520px;
        flex-direction: row-reverse;
      }

      .textContainer {
        flex-grow: 1;
      }

      .image {
        height: 115px;
        width: 154px;
        /* stylelint-disable-next-line property-no-unknown */
        aspect-ratio: 4/3;
        object-fit: cover;
      }

      .readMore {
        font-size: 20px;
        line-height: 24px;
      }

      .headline {
        font-size: 16px;
        line-height: 22px;
      }

      /* Mobile */
      @media screen and (max-width: 520px) {
        .container {
          min-width: 308px;
          max-width: 520px;
          flex-direction: column;
        }

        .image {
          height: unset;
          width: 100%;
          /* stylelint-disable-next-line property-no-unknown */
          aspect-ratio: 4/3;
          object-fit: cover;
        }

        .readMore {
          font-size: 24px;
          line-height: 24px;
        }

        .headline {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    /* Desktop styles */
    &[data-platform='cc'],
    &[data-platform='mol.web.desktop'] {
      .container {
        flex-direction: column;
        min-width: 308px;
        max-width: 308px;
      }

      .image {
        /* stylelint-disable-next-line property-no-unknown */
        aspect-ratio: 4/3;
        width: 100%;
        height: unset;
        padding: 4px 0;
        align-self: stretch;
        object-fit: cover;
      }

      .readMore {
        font-size: 24px;
        line-height: 24px;
      }

      .headline {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .headline:has(.headlineFlagsWrapper) {
      display: inline;
      line-height: 24px;
    }

    .headlineFlagsWrapper {
      display: inline-flex;
      align-content: flex-start;
      height: 20px;
      position: relative;
      top: 3px;

      > :last-child {
        margin-right: 0 !important;
      }

      .is-paywalled {
        top: 0;
        height: 20px;
      }

      .molLivePulseWrapper {
        .molLivePulse {
          background: red;
          height: 20px;
          display: flex;
          align-items: center;
          padding: 0 4px;
          margin-right: 4px;

          .molBulletIcon {
            background: radial-gradient(circle, transparent 53%, rgba(187, 25, 25, 0.5) 53.5%, #fff 60%);
            border-radius: 50%;
            display: inline-block;
            height: 10px;
            width: 10px;
            margin-right: 3px;
            position: relative;
            vertical-align: middle;

            .molLivePulseCircle {
              animation: mol-live-pulse 1.7s ease infinite;
              background: radial-gradient(circle, #fff 37%, rgba(187, 25, 25, 0.5) 37.5%, transparent 38%);
              border-radius: 50%;
              content: '';
              height: 100%;
              left: 0;
              padding: 0;
              position: absolute;
              width: 100%;
            }
          }

          .molLiveBulletText {
            color: #fff;
            font-family: 'Helvetica Neue', sans-serif;
            font-size: 13px;
            font-weight: 700;
            line-height: 19px;
          }
        }
      }

      .molExclusiveWrapper,
      .molBreakingNewsWrapper {
        .molExclusive,
        .molBreakingNews {
          background: red;
          height: 20px;
          display: flex;
          align-items: center;
          padding: 0 4px;
          margin-right: 4px;

          .molExclusiveText,
          .molBreakingNewsText {
            color: #fff;
            font-family: 'Helvetica Neue', sans-serif;
            font-size: 13px;
            font-weight: 700;
            line-height: 19px;
          }
        }
      }
    }

    .headlineFlagsWrapper::before {
      content: '';
      display: block;
      width: 0;
      height: 20px;
    }

    .headlineText {
      color: white;
    }
  }
}
