.imageBox {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #d8d8d8;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
